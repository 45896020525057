import React, { useState, useCallback } from 'react';

import { Container, Row } from 'reactstrap';

import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
// import { photos } from './photos';

function ContentImageWall(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { cover, coverHeight, coverWidth, pictures } = props;
  const imagesPic= [{ src: cover, height: coverHeight, width: coverWidth }];
  // const imagesPic = [ImageWithAspect({ cover })];
  pictures.map((item) => {
    return imagesPic.push(item);
  });

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      <div
        className="section section-content"
        style={{
          backgroundColor: '#fefefe',
        }}
      >
        <Container>
          {/* <Row style={{ justifyContent: "center" }}>
            <h2 style={{ fontWeight: "bold" }}>ทดสอบหัวข้อ</h2>
          </Row> */}
          <Row>
            <Gallery photos={imagesPic} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox} allowFullscreen={false}>
                  <Carousel
                    currentIndex={currentImage}
                    views={imagesPic.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContentImageWall;
