/* eslint react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import BarLoader from 'react-spinners/BarLoader';
// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.js';
import DarkFooter from 'components/Footers/DarkFooter.js';

//sections for editing
import HeadImageWall from './image-wall-sections/HeadImageWall';
import ContentImageWall from './image-wall-sections/ContentImageWall';

function ImageWall({ isWIP }) {
  const [post, setPost] = useState(null);
  const [topic, setTopic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const id = useParams();

  const getData = async () => {
    setLoading(true);
    try {
      // console.log('try to get');
      const res = await axios.get(
        isWIP ? `/api/wip/${id.id}` : `/api/posts/${id.id}`
      );
      setPost(res.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log('started effect');
    getData();
  }, []);

  useEffect(() => {
    if (post) {
      setTopic(post);
      // console.log('Axios Complete:' + postId);
      // console.log('post');
      // console.log(post);
      // console.log('topic');
      // console.log(topic);
      // console.log(title);
    }
  }, [post]);

  useEffect(() => {
    document.body.classList.add('index-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('index-page');
      document.body.classList.remove('sidebar-collapse');
    };
  });

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
          zIndex: '9999',
          backgroundColor: '#fff',
          position: 'fixed',
          top: '0',
          left: '0',
          flexDirection: 'column',
          fontSize: '50px',
          fontWeight: 'bold',
          color: '#003f88',
        }}
      >
        <div>Loading...</div>
        <img src={require('assets/img/logoAsset-1.png').default} alt="" />
        <BarLoader color="#003f88" height={20} width={300} />
      </div>
    );
  }

  if (error) {
    return <div>error</div>;
  }

  return (
    <>
      <IndexNavbar paramsID={id.id} />
      <div className="wrapper">
        <div className="main">
          <HeadImageWall HeadName={topic.post} bgHeadImage={topic.img} />
          <ContentImageWall cover={topic.cover} coverHeight={topic.coverHeight} coverWidth={topic.coverWidth} pictures={topic.images} />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default ImageWall;
