import React from "react";

import { Container, Row, Col } from "reactstrap";

function ContactNeed() {
  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/section-bg2.jpg").default + ")",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="section section-contactus"
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col
                lg="12"
                style={{
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "1.1rem",
                }}
              >
                <h2>สิ่งที่ท่านต้องการ คืองานอันภาคภูมิใจของเรา</h2>
                <p style={{ textAlign: "justify" }}>
                  เรามีทีมช่างผู้เชี่ยวชาญ ยินดีให้คำปรึกษา
                  และลงพื้นที่ตรวจสอบหน้างาน เพื่อประเมินราคาเบื้องต้นให้ท่าน
                  พิจารณาก่อนการตัดสินใจ โดยไม่คิดค่าใช้จ่ายใด ๆ ทั้งสิ้น
                </p>
                <p style={{ marginTop: "2rem" }}>
                  <a
                    className="btn index-contact-button facebook"
                    href="https://fb.me/Saenthorranee"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-facebook-square fa-2x"
                      style={{ marginRight: "10px", verticalAlign: "sub" }}
                    ></i>
                    <span>@Saenthorranee </span>
                  </a>
                  <a
                    className="btn index-contact-button messenger"
                    href="https://m.me/Saenthorranee"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-facebook-messenger fa-2x"
                      style={{ marginRight: "10px", verticalAlign: "sub" }}
                    ></i>
                    <span>@Saenthorranee</span>
                  </a>
                  <a
                    className="btn index-contact-button line"
                    href="https://line.me/R/ti/p/@383hkjwu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-line fa-2x"
                      style={{ marginRight: "10px", verticalAlign: "sub" }}
                    ></i>
                    ติดต่อสอบถามผ่านไลน์
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ContactNeed;
