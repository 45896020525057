/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="6" xs="12">
            <h3 className="mb-3">ห้างหุ้นส่วนจำกัด แสนธรณี</h3>
            <div className="spacing-footer">
              <span>
                บริการรับเหมาก่อสร้างครบวงจร ออกแบบ รีโนเวท ต่อเติม
                ครอบคลุมงานก่อสร้างทุกชนิด ไม่ว่าจะเป็น บ้าน อาคาร สำนักงาน
                คอนโดฯ และโรงงานของท่าน เราสามารถรังสรรค์ตามที่ท่านต้องการ
                ด้วยทีมงานคุณภาพ จากประสบการณ์การทำงานที่มีมากกว่า 15 ปี
                พร้อมทั้งมีวิศวกร
                และสถาปนิกที่เชี่ยวชาญคอยควบคุมงานอย่างเคร่งครัด
              </span>
            </div>
          </Col>
          <Col lg="5" md="5" sm="6" xs="12">
            <h3 className="mb-3">ติดต่อเรา</h3>
            <div className="spacing-footer" style={{ padding: "10px" }}>
              <div>
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <span>
                ตั้งอยู่ที่ 14/3 หมู่ที่ 7 ซอยบางกรวย-ไทรน้อย 15/2
                ถนนบางกรวย-ไทรน้อย ตำบลบางกรวย อำเภอบางกรวย จังหวัดนนทบุรี 11130
              </span>
            </div>
            <a href="tel:+6663-354-5397" className="spacing-footer footer-btn">
              <div>
                <i className="fas fa-phone-alt"></i>
              </div>
              <span>063-354-5397</span>
            </a>
            <a
              href="mailto:saenthoraneegw@gmail.com"
              className="spacing-footer footer-btn"
            >
              <div>
                <i className="fas fa-envelope"></i>
              </div>
              <span>saenthoraneegw@gmail.com</span>
            </a>
          </Col>
          <Col lg="3" md="3" sm="6" xs="12">
            <h3 className="mb-3">ติดตามผลงานของเรา</h3>
            <a
              href="https://fb.me/Saenthorranee"
              target="_blank"
              className="spacing-footer footer-btn"
            >
              <div>
                <i className="fab fa-facebook"></i>
              </div>
              <span>@Seanthorranee</span>
            </a>
            <a href="tel:+6663-354-5397" className="spacing-footer footer-btn">
              <div>
                <i className="fas fa-phone-alt"></i>
              </div>
              <span>063-354-5397 (คุณอัมรา)</span>
            </a>
            <a href="tel:+6687-499-6359" className="spacing-footer footer-btn">
              <div>
                <i className="fas fa-phone-alt"></i>
              </div>
              <span>087-499-6359 (ช่างเอ)</span>
            </a>
            <a href="tel:+6680-965-3550" className="spacing-footer footer-btn">
              <div>
                <i className="fas fa-phone-alt"></i>
              </div>
              <span>080-965-3550 (คุณกฤษฎา)</span>
            </a>
          </Col>
        </Row>
        <br />
        <Row style={{ justifyContent: "center" }}>
          <div>
            Copyright © 2021 Saenthorranee Limited Partnership All rights
            reserved.
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DarkFooter;
