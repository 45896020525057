import React from 'react';
import { useLocation } from 'react-router-dom';

//import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  // UncontrolledTooltip,
} from 'reactstrap';
import ChatButton from './ChatButton';

function CheckPathName(path) {
  const location = useLocation();
  if (location.pathname === path) {
    return true;
  } else {
    return false;
  }
}

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const {paramsID} = props;
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 199 ||
        document.body.scrollTop > 199
      ) {
        setNavbarColor('');
      } else if (
        document.documentElement.scrollTop < 200 ||
        document.body.scrollTop < 200
      ) {
        setNavbarColor('navbar-transparent');
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  return (
    <>
      <ChatButton />
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={'fixed-top ' + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/index"
              id="navbar-brand"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.5em',
                fontWeight: 'bold',
              }}
            >
              <img
                src={require('assets/img/logoAsset-3.png').default}
                style={{
                  height: '50px',
                }}
                alt=""
              />{' '}
              &nbsp;&nbsp;&nbsp;SAENTHORRANEE
            </NavbarBrand>
            {/* <UncontrolledTooltip target="#navbar-brand">
              Designed by Invision. Coded by Creative Tim
            </UncontrolledTooltip> */}
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <div
                className="d-block d-lg-none"
                style={{ textAlign: 'center' }}
              >
                <img
                  src={require('assets/img/logoAsset-1.png').default}
                  alt=""
                  style={{
                    padding: '20px',
                    width: '200px',
                    height: '200px',
                  }}
                />
              </div>
              <NavItem active={CheckPathName('/index')}>
                <NavLink href="/index">
                  <p style={{ fontSize: '1rem', verticalAlign: 'middle' }}>
                    หน้าแรก{CheckPathName('index')}
                  </p>
                </NavLink>
              </NavItem>
              <NavItem active={CheckPathName('/about-us')}>
                <NavLink href="/about-us">
                  <p style={{ fontSize: '1rem', verticalAlign: 'middle' }}>
                    เกี่ยวกับเรา
                  </p>
                </NavLink>
              </NavItem>
              <NavItem active={CheckPathName('/services')}>
                <NavLink href="/services">
                  <p style={{ fontSize: '1rem', verticalAlign: 'middle' }}>
                    บริการของเรา
                  </p>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  CheckPathName('/portfolio') || CheckPathName(`/portfolio/${paramsID}`)
                }
              >
                <NavLink href="/portfolio">
                  <p style={{ fontSize: '1rem', verticalAlign: 'middle' }}>
                    ผลงานของเรา
                  </p>
                </NavLink>
              </NavItem>
              <NavItem active={CheckPathName('/profile')}>
                <NavLink href="/profile">
                  <p style={{ fontSize: '0.8rem', verticalAlign: 'middle' }}>
                    Profile
                  </p>
                </NavLink>
              </NavItem>
              <NavItem active={CheckPathName('/contact-us')}>
                <NavLink href="/contact-us">
                  <p style={{ fontSize: '1rem', verticalAlign: 'middle' }}>
                    ติดต่อเรา
                  </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className="nav-link btn btn-contact-price"
                  color="info"
                  href="/contact-us"
                >
                  <p>ขอใบเสนอราคา</p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
