/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header clear-filter"
        // filter-color="yellow"
        style={{ backgroundColor: "rgba(255,255,255,0.4)" }}
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/header2.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div
            className="content-center brand"
            style={{
              // backgroundColor: "#fffffff5",
              // padding: "20px",
              // borderRadius: "40px",
              // fontSize: "0.9em",
            }}
          >
            <img
              alt="..."
              className="n-caption"
              // src={require("assets/img/now-logo.png").default}
              src={require("assets/img/comp1.png").default}
            />
            {/* <h1 style={{ fontWeight: "bold", fontSize: "4em" }}>
              เคยได้ยินไหม?
            </h1>
            <h1 style={{ fontWeight: "bold", color: "red" }}>
              ผู้รับเหมาทิ้งงาน บ้านไม่เสร็จ
            </h1>
            <h1 style={{ fontWeight: "bold" }}>
              ปัญหานี้จะหมดไป ถ้าคุณใช้บริการ กับ
            </h1>
            <h1 style={{ fontWeight: "bold", fontSize:"5em" }}>
              แสนธรณี
            </h1> */}
            {/* <h5>เพราะ...ถูกทิ้งงานสร้างบ้าน</h5> */}
          </div>

          <a
            href="https://www.freepik.com/photos/technology"
            target="_blank"
            className="credit-picture"
          >
            Technology photo created by rawpixel.com - www.freepik.com
          </a>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
