import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';

function ProjectCard(props) {
  // eslint-disable-next-line
  const {
    TitleText,
    ImgCard,
    FileName,
    sendID,
    setMaxHeight,
    Blanks,
    FetchCard,
  } = props;
  return (
    <>
      <a
        //{blank ? (className="project-card-showcase d-none") : (className="project-card-showcase")}
        className={
          Blanks
            ? 'project-card-showcase d-none d-md-block d-lg-block d-xl-block content-hidden'
            : 'project-card-showcase'
        }
        href={FileName}
        target={FetchCard ? '_self' : '_blank'}
        rel="noreferrer"
      >
        <div className="project-card-div" id={sendID}>
          <img
            src={ImgCard}
            alt=""
            //style={{ height: setMaxHeight, width: "100%" }}
          />
          <div className="project-card-text">
            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {TitleText}
            </span>
            <span style={{ fontSize: '0.8rem' }}>View Project</span>
          </div>
        </div>
      </a>
    </>
  );
}

function WorkInProgress() {
  const [heightMax, setHeight] = useState(0);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [chunkedPosts, setChunkedPosts] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      // console.log('try to get');
      const res = await axios.get('/api/wip/');
      setPost(res.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // setHeight(document.getElementById('mainHeightProject2').clientHeight);
  }, []);

  useEffect(() => {
    if (post) {
      // Chunk the posts when 'post' changes
      const chunkSize = 2; // Number of items per row
      const chunkedPosts = [];

      for (let i = 0; i < post.length; i += chunkSize) {
        chunkedPosts.push(post.slice(i, i + chunkSize));
      }
      setChunkedPosts(chunkedPosts);
    }
  }, [post]); // Run this effect whenever 'post' changes

  return (
    <>
      {post && post.length > 0 ? (
        <div
          className="section section-portfolio"
          style={{
            backgroundColor: '#fafafa',
            paddingBottom: '0',
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col
                lg="12"
                style={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                }}
              >
                <h6 style={{ fontWeight: '500' }}>Work in progress</h6>
                <h2 style={{ fontWeight: 'bold' }}>งานที่ดำเนินการอยู่</h2>
              </Col>
            </Row>
          </Container>
          {chunkedPosts.map((chunk, index) => (
            <React.Fragment key={index}>
              {chunk.length > 0 && (
                <Container key={index} fluid={chunk.length === 2}>
                  <Row
                    className={
                      chunk.length === 1
                        ? 'project-layout for-wip'
                        : 'project-layout'
                    }
                  >
                    {chunk.map((item, idx) => (
                      <ProjectCard
                        key={idx}
                        TitleText={item.title}
                        ImgCard={item.img}
                        FileName={`/wip/${item.post}`}
                        Blanks={false}
                        sendID={`mainHeightProject2-${idx}`}
                        setMaxHeight="100%"
                        FetchCard
                      />
                    ))}
                  </Row>
                </Container>
              )}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default WorkInProgress;
{
  /* <p>
  ด้วยทีมงานช่างผู้เชี่ยวชาญของเรา
  เราจึงกล้ารับประกันคุณภาพงานทุกงานที่ทำ ให้กับลูกค้า ตั้งแต่ 6
  เดือน จนถึง 5 ปี
</p> */
}
// {chunk.length === 1 && (
//   <Container key={index}>
//     <Row className={`project-layout for-wip`}>
//       {chunk.map((item, idx) => (
//         <ProjectCard
//           key={idx}
//           TitleText={item.title}
//           ImgCard={item.img}
//           FileName={`/wip/${item.post}`}
//           Blanks={false}
//           sendID={`mainHeightProject2-${idx}`}
//           setMaxHeight="100%"
//           FetchCard
//         />
//       ))}
//     </Row>
//   </Container>
// )}
// {chunk.length === 2 && (
//   <Row key={index} className="project-layout">
//     {chunk.map((item, idx) => (
//       <ProjectCard
//         key={idx}
//         TitleText={item.title}
//         ImgCard={item.img}
//         FileName={`/wip/${item.post}`}
//         Blanks={false}
//         sendID={`mainHeightProject2-${idx}`}
//         setMaxHeight="100%"
//         FetchCard
//       />
//     ))}
//   </Row>
// )}
{
  /* {chunkedPosts.map((chunk, index) => (
            <Container>
              <Row key={index} className="project-layout for-wip">
                {chunk.map((item, idx) => (
                  <ProjectCard
                    key={idx}
                    TitleText={item.title}
                    ImgCard={item.img}
                    FileName={`/wip/${item.post}`}
                    Blanks={false}
                    sendID={`mainHeightProject2-${idx}`}
                    setMaxHeight="100%"
                    FetchCard
                  />
                ))}
              </Row>
            </Container>
          ))} */
}
{
  /* <Row className="project-layout for-wip">
              <ProjectCard
                TitleText={`โครงการ " อาคารพักอาศัย ค.ส.ล. สูง 4 ชั้น JCP ARGOTECH HQ "`}
                ImgCard={require("assets/img/cover/JCP ARGOTECH HQ.jpg").default}
                FileName={
                  require("assets/pdf/wip/6 JCP ARGOTECH HQ.pdf").default
                }
                sendID="mainHeightProject2"
                setMaxHeight="100%"
                Blanks={false}
                // setMaxHeight={heightMax}
              />
          </Row> */
}
{
  /* <Row className="project-layout for-wip">
            <ProjectCard
              TitleText={`โครงการบ้านพักอาศัย 3 ชั้น [บ้านคุณหมอเอิร์ท อินทามระซอย4] "`}
              ImgCard={
                require('assets/img/cover/โครงการบ้านพักอาศัย 3 ชั้น บ้านคุณหมอเอิร์ท อินทามระซอย4.jpg')
                  .default
              }
              FileName={
                require('assets/pdf/wip/7 โครงการบ้านพักอาศัย 3 ชั้น บ้านคุณหมอเอิร์ท อินทามระซอย4.pdf')
                  .default
              }
              sendID="mainHeightProject2"
              setMaxHeight="100%"
              Blanks={false}
              // setMaxHeight={heightMax}
            />
          </Row> */
}
