/* eslint react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BarLoader from 'react-spinners/BarLoader';
import { source } from 'views/index-sections/projectFixed/source';
import { Container, Row } from 'reactstrap';

function ContentCard(props) {
  // eslint-disable-next-line
  const { TitleText, ImgCard, FileName, sendID, setMaxHeight } = props;
  return (
    <>
      <a
        href={FileName}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'inline-block',
          marginBottom: '10px',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <div className="subject-card-div" id={sendID}>
          <img src={ImgCard} alt="" />
          <div className="project-card-text">
            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {TitleText}
            </span>
            <span style={{ fontSize: '0.8rem' }}>View Project</span>
          </div>
        </div>
      </a>
    </>
  );
}

function FetchCard(props) {
  // eslint-disable-next-line
  const { TitleText, ImgCard, cardID } = props;
  return (
    <>
      <a
        href={`/portfolio/${cardID}`}
        rel="noreferrer"
        style={{
          display: 'inline-block',
          marginBottom: '10px',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <div className="subject-card-div">
          <img src={ImgCard} alt="" />
          <div className="project-card-text">
            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {TitleText}
            </span>
            <span style={{ fontSize: '0.8rem' }}>View Project</span>
          </div>
        </div>
      </a>
    </>
  );
}

function ContentProject() {
  // const [heightMax, setHeight] = useState(0);
  const [post, setPost] = useState(null);
  const [topic, setTopic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      // console.log('try to get');
      const res = await axios.get('/api/posts/');
      setPost(res.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // setHeight(document.getElementById('mainHeightProject').clientHeight);
    // console.log('started effect');
    getData();
  }, []);

  useEffect(() => {
    if (post) {
      setTopic(post);
      // console.log('Axios Complete:' + postId);
      // console.log('post');
      // console.log(post);
      // console.log('topic');
      // console.log(topic);
      // console.log(title);
    }
  }, [post]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
          zIndex: '9999',
          backgroundColor: '#fff',
          position:"fixed",
          top:"0",
          left:"0",
          flexDirection:"column",
          fontSize: '50px',
          fontWeight: 'bold',
          color: '#003f88',
        }}
      >
        <div>Loading...</div>
        {/* <img src={require('assets/img/logoAsset-1.png').default} alt="" /> */}
        <BarLoader color="#003f88" height={20} width={300} />
      </div>
    );
  }

  if (error) {
    return <div>error</div>;
  }
  return (
    <>
      <div
        className="section section-content"
        style={{
          backgroundColor: '#fefefe',
        }}
      >
        <Container>
          <Row className="subject-layout">
            {topic.map((item, index) => (
              <FetchCard
                TitleText={item.title}
                ImgCard={item.img}
                cardID={item.post}
              />
            ))}
            {source.map((item, index) => (
              <ContentCard
                TitleText={item.TitleText}
                ImgCard={item.ImgCard}
                FileName={item.FileName}
              />
            ))}
            {/* <ContentCard
              TitleText={'งานปรับปรุงและต่อเติมบ้าน\nซอยรามคำแหง 80'}
              ImgCard={require('assets/img/cover/รามคำแหง80 3.jpg').default}
              FileName={
                require('assets/pdf/ltp/7 รีโนเวทบ้านรามคำแหง 80 โดย หจก แสนธรณี.pdf')
                  .default
              }
              // setMaxHeight={heightMax}
            />
            <ContentCard
              TitleText={
                'งานก่อสร้างบ้านพักอาศัย 2 ชั้น 1 หลัง บ้าน\nท่านพลอากาศโท แมนศีตะจิตต์'
              }
              ImgCard={require('assets/img/cover/รามอินทรา 21 2.jpg').default}
              FileName={
                require('assets/pdf/ltp/1 โครงการก่อสร้าง บ้านพักอาศัย 2 ชั้น 1 หลัง บ้านท่าน พลอากาศโท แมนศีตะจิตต์.pdf')
                  .default
              }
              // setMaxHeight={heightMax}
            />
            <ContentCard
              TitleText={'งานปรับปรุงและต่อเติมคลีนิกกายภาพบำบัด\nนวมินทร์ 85'}
              ImgCard={require('assets/img/cover/นวมินทร์ 85 2.jpg').default}
              FileName={require('assets/pdf/ltp/2 นวมินทร์ 85.pdf').default}
              sendID="mainHeightProject"
              setMaxHeight="100%"
            />
            <ContentCard
              TitleText={'งานปรับปรุงและต่อเติมบ้าน\nซอยจรัญ 18'}
              ImgCard={require('assets/img/cover/จรัญ 18 1.jpg').default}
              FileName={
                require('assets/pdf/ltp/3 โครงการปรับปรุงและต่อเติมบ้าน ซอยจรัญ18.pdf')
                  .default
              }
              // setMaxHeight={heightMax}
            />
            <ContentCard
              TitleText={'งานทาสีอาคาร บริษัท แอดวานซ์ อเบรซีฟ'}
              ImgCard={require('assets/img/cover/สะแกงาม 1.jpg').default}
              FileName={
                require('assets/pdf/ltp/4 งานทาสีอาคาร บริษัท แอดวานซ์ อเบรซีฟ ซอยสะแกงาม35.pdf')
                  .default
              }
              // setMaxHeight={heightMax}
            />
            <ContentCard
              TitleText={'งานปรับปรุงและต่อเติมบ้าน\nพุทธมณฑลสาย 2'}
              ImgCard={require('assets/img/cover/บ้านสาย 2 3.jpeg').default}
              FileName={
                require('assets/pdf/ltp/5 โครงการปรับปรุงและต่อเติมบ้าน พุทธมณฑลสาย 2.pdf')
                  .default
              }
              // setMaxHeight={heightMax}
            />
            <ContentCard
              TitleText={'งานปรับปรุงและต่อเติมสำนักงาน รัตนาธิเบศน์'}
              ImgCard={require('assets/img/cover/รัตนาธิเบศน์3.jpg').default}
              FileName={
                require('assets/pdf/ltp/6 โครงการปรับปรุงสำนักงาน รัตนาธิเบศน์.pdf')
                  .default
              }
              // setMaxHeight={heightMax}
            /> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContentProject;
