/* eslint react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BarLoader from 'react-spinners/BarLoader';
import { source } from 'views/index-sections/projectFixed/source';
import { Container, Row } from 'reactstrap';

function ContentCard(props) {
  // eslint-disable-next-line
  const { TitleText, ImgCard, FileName, sendID, setMaxHeight } = props;
  return (
    <>
      <div class="responsive">
        <div class="gallery">
          <a target="_blank" href={FileName} rel="noreferrer">
            <img src={ImgCard} alt="" />
            <div class="desc">{TitleText}</div>
          </a>
        </div>
      </div>
      {/* <a
        href={FileName}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'inline-block',
          marginBottom: '10px',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <div className="subject-card-div" id={sendID}>
          <img src={ImgCard} alt="" />
          <div className="project-card-text">
            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {TitleText}
            </span>
            <span style={{ fontSize: '0.8rem' }}>View Project</span>
          </div>
        </div>
      </a> */}
    </>
  );
}

function FetchCard(props) {
  // eslint-disable-next-line
  const { TitleText, ImgCard, cardID } = props;
  return (
    <>
      <div class="responsive">
        <div class="gallery">
          <a target="_blank" href={`/portfolio/${cardID}`} rel="noreferrer">
            <img src={ImgCard} alt="" />
            <div class="desc">{TitleText}</div>
          </a>
        </div>
      </div>
      {/* <a
        href={`/portfolio/${cardID}`}
        rel="noreferrer"
        style={{
          display: 'inline-block',
          marginBottom: '10px',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <div className="subject-card-div">
          <img src={ImgCard} alt="" />
          <div className="project-card-text">
            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {TitleText}
            </span>
            <span style={{ fontSize: '0.8rem' }}>View Project</span>
          </div>
        </div>
      </a> */}
    </>
  );
}

function ContentProfile() {
  const [post, setPost] = useState(null);
  const [topic, setTopic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      // console.log('try to get');
      const res = await axios.get('/api/posts/');
      setPost(res.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (post) {
      setTopic(post);
    }
  }, [post]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
          zIndex: '9999',
          backgroundColor: '#fff',
          position: 'fixed',
          top: '0',
          left: '0',
          flexDirection: 'column',
          fontSize: '50px',
          fontWeight: 'bold',
          color: '#003f88',
        }}
      >
        <div>Loading...</div>
        {/* <img src={require('assets/img/logoAsset-1.png').default} alt="" /> */}
        <BarLoader color="#003f88" height={20} width={300} />
      </div>
    );
  }

  if (error) {
    return <div>error</div>;
  }
  return (
    <>
      <div
        className="section section-content"
        style={{
          backgroundColor: '#fefefe',
          fontFamily: 'Thasadith, sans-serif',
        }}
      >
        <Container>
          <div>
            <h2 style={{ fontWeight: 'bold' }}>ห้างหุ้นส่วนจำกัด แสนธรณี</h2>
            {/* <p style={{ fontSize: '18px', fontWeight: '600' }}>
              ปีทีจัดตั้ง : 2549
            </p> */}
            <p style={{ fontSize: '18px', fontWeight: '600' }}>
              ความเชี่ยวชาญ : ก่อสร้างบ้านพักอาศัย/อาคารพาณิชย์/อาคารพักอาศัย
            </p>
            <p style={{ fontSize: '18px', fontWeight: '600' }}>
              <span>
                ห้างหุ้นส่วน แสนธรณี จำกัด ตั้งสำนักงานอยู่ที่ 14/3 หมู่ 7
                ซ.บางกรวยไทรน้อย 15/2 ถ.บางกรวยไทรน้อย อ.บางกรวย ต.บางกรวย
                จ.นนทบุรี หจก ฯ ก่อตั้ง เมื่อเดือน มกราคม 2561 ด้วยทุนจดทะเบียน
                1 ล้านบาท หจก ฯ ให้บริการออกแบบและก่อสร้างทั่วประเทศ
              </span>
              <br />
              <span>
                หจก ฯ ได้ดำเนินงานด้านธุรกิจก่อสร้างบ้านพักอาศัย อาคาร สำนักงาน
                และอาคารพักอาศัย มูลค่ากว่า 70
                ล้านบาทโดยเริ่มก่อสร้างครั้งแรกเมื่อปี 2549 ต่อมาภายในปี 2561
                จึงได้ก่อตั้งห้างหุ้นส่วนจำกัดแสนธรณี ขึ้นมา
                เพื่อต้องการรองรับงานที่มีมูลค่ามากขึ้น
                และสร้างความเชื่อมั่นให้กับท่าน
              </span>
            </p>
          </div>
          {/* บริษัท อาร์ซี ดีคอน จำกัด
ปีทีจัดตั้ง : 2547
ปีที่เริ่มใช้ Builk : 2557
ความเชี่ยวชาญ : บ้าน/อาคารที่อยู่อาศัย
ประวัติ
บริษัท อาร์ซี ดีคอน จำกัด ตั้งสำนักงานอยู่ที่ 213 ซอย 10 ถนนเพชรเกษม อำเภอหาดใหญ่ จังหวัดสงขลา 90110 บริษัท ฯ ก่อตั้ง เมื่อวันที่ 30 มกราคม 2547 ด้วยทุนจดทะเบียน 6 ล้านบาท บริษัท ฯ ให้บริการออกแบบและก่อสร้างทั่วประเทศ ตลอดจนประเทศใกล้เคียง ที่ผ่านมา บริษัท อาร์ซี ดีคอน จำกัด ได้ดำเนินงานด้านธุรกิจก่อสร้างอาคาร สำนักงาน และอาคารพักอาศัย มูลค่ากว่า 200 ล้านบาท

Comapany Background
RC Decon Company Limited is located at 213 Soi 10/13 Petkasem Road, Hatyai, Songkhla 90110. The company is esta- blished on January 30th, 2004 with the capital registration of six million baht. RC Decon Company Limited provides design and construction services all over Thailand and neighboring countries. The company has developed and constructed in excess of over 200 million baht in office buildings, and various styles of residences. */}
          <hr />
          {/* <Row className="subject-layout"> */}
          <Row className="box-profile">
            {topic.map((item, index) => (
              <FetchCard
                TitleText={item.title}
                ImgCard={item.img}
                cardID={item.post}
              />
            ))}
            {source.map((item, index) => (
              <ContentCard
                TitleText={item.TitleText}
                ImgCard={item.ImgCard}
                FileName={item.FileName}
              />
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContentProfile;
