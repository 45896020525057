import React from "react";

function ChatButton() {
  return (
    <>
      <div className="menu">
        <input
          type="checkbox"
          href="#"
          className="menu-open"
          name="menu-open"
          id="menu-open"
        />
        <label className="menu-open-button" for="menu-open">
          <div className="chat-icons">
            <i className="fas fa-comments chat-icon-default"></i>
            <i className="fas fa-times chat-icon-close"></i>
          </div>
        </label>
        <a
          href="https://m.me/Saenthorranee"
          target="_blank"
          rel="noreferrer"
          className="menu-item messenger"
        >
          <i className="fab fa-facebook-messenger"></i>
        </a>
        <a
          href="https://line.me/R/ti/p/@383hkjwu"
          target="_blank"
          rel="noreferrer"
          className="menu-item line"
        >
          <i className="fab fa-line"></i>
        </a>
        <a
          href="https://fb.me/Saenthorranee"
          target="_blank"
          rel="noreferrer"
          className="menu-item facebook"
        >
          <i className="fab fa-facebook-square"></i>
        </a>
      </div>
    </>
  );
}

export default ChatButton;
