import React from "react";

import { Container, Row, Col } from "reactstrap";

function CheckedContent(props) {
  const { Text } = props;
  return (
    <Col md="4" style={{ marginBottom: "30px" }}>
      <div
        style={{ fontSize: "1.1rem", display: "flex", alignItems: "center" }}
      >
        <i
          className="fas fa-check fa-2x"
          style={{ color: "#003f88", marginRight: "10px" }}
        ></i>
        <p style={{ marginBottom: "0" }}>{Text}</p>
      </div>
    </Col>
  );
}

function ContentServices() {
  return (
    <>
      <div
        className="section section-content"
        style={{
          backgroundColor: "#fefefe",
          paddingBottom: "0",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              lg="12"
              style={{
                textAlign: "center",
                fontSize: "1.1rem",
              }}
            >
              <h4
                style={{ color: "#003f88", fontWeight: "bold", marginTop: "0" }}
              >
                บริการรับเหมาก่อสร้าง
              </h4>
              <p>
                บริการของเรามีรับเหมาก่อสร้าง ต่อเติม ซ่อมแซม ซ่อมบำรุง บ้าน
                อาคาร สำนักงาน ตึก โรงงาน
                รวมถึงรับเหมาต่อเติมซ่อมแซมก่อสร้างรีโนเวท ฯลฯ
              </p>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: "20px" }}>
          <Row className="justify-content-center">
            <CheckedContent
              Text={["บริการซ่อม สร้างหลังคา", <br />, "งานซ่อมหลังคารั่ว"]}
            />
            <CheckedContent
              Text={["บริการรับทาสีบ้านอาคาร", <br />, "ตึกโรงงาน"]}
            />
            <CheckedContent
              Text={[
                "บริการซ่อมกันซึมดาดฟ้า ผนังอาคาร",
                <br />,
                "กันรั่วซึมหลังคาโรงงาน",
              ]}
            />
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginBottom: "30px" }}
          >
            <CheckedContent
              Text={[
                "บริการซ่อมประตู ซ่อมรั้ว",
                <br />,
                "ทั้งเหล็ก ปูน และไม้",
              ]}
            />
            <CheckedContent
              Text={[
                "บริการซ่อม ปูพื้นคอนกรีต",
                <br />,
                "งานฝ้า กั้นห้อง กั้นผนัง",
              ]}
            />
            <CheckedContent
              Text={["บริการซ่อม สร้างห้องน้ำ", <br />, "ระบบประปาและไฟฟ้า"]}
            />
          </Row>
        </Container>
        <Container>
          <Row
            className="justify-content-center"
            style={{ marginBottom: "1rem" }}
          >
            <Col
              lg="12"
              style={{
                color: "#003f88",
                textAlign: "center",
                fontSize: "1.1rem",
              }}
            >
              <h4 style={{ fontWeight: "bold" }}>
                สนใจบริการ สอบถามข้อมูลเพิ่มเติม
              </h4>
              <p style={{ marginTop: "2rem" }}>
                <a
                  className="btn index-contact-button facebook"
                  href="https://fb.me/Saenthorranee"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fab fa-facebook-square fa-2x"
                    style={{ marginRight: "10px", verticalAlign: "sub" }}
                  ></i>
                  <span>@Saenthorranee </span>
                </a>
                <a
                  className="btn index-contact-button messenger"
                  href="https://m.me/Saenthorranee"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fab fa-facebook-messenger fa-2x"
                    style={{ marginRight: "10px", verticalAlign: "sub" }}
                  ></i>
                  <span>@Saenthorranee</span>
                </a>
                <a
                  className="btn index-contact-button line"
                  href="https://line.me/R/ti/p/@383hkjwu"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fab fa-line fa-2x"
                    style={{ marginRight: "10px", verticalAlign: "sub" }}
                  ></i>
                  ติดต่อสอบถามผ่านไลน์
                </a>
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col sm="4" style={{ padding: "0" }}>
              <img src={require("assets/img/service_1.jpg").default} alt="" />
            </Col>
            <Col sm="4" style={{ padding: "0" }}>
              <img src={require("assets/img/service_2.jpg").default} alt="" />
            </Col>
            <Col sm="4" style={{ padding: "0" }}>
              <img src={require("assets/img/service_3.jpg").default} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContentServices;
