import React from "react";

import { Container, Row, Col } from "reactstrap";

function CheckedContent(props) {
  const { Text } = props;
  return (
    <p>
      <i
        className="fas fa-check-circle"
        style={{ color: "#003f88", marginRight: "10px" }}
      ></i>
      <span>{Text}</span>
    </p>
  );
}

function ContentAbout() {
  return (
    <>
      <div
        className="section section-content"
        style={{
          backgroundColor: "#fefefe",
          paddingBottom: "0",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              lg="12"
              style={{
                textAlign: "center",
                fontSize: "1.1rem",
              }}
            >
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#003f88",
                  margin: "1rem 0 60px 0",
                }}
              >
                ห้างหุ้นส่วนจำกัด แสนธรณี
              </h2>
              <p>
                ห้างหุ้นส่วนจำกัดแสนธรณี ของเราให้บริการออกแบบ
                และรับเหมาก่อสร้างครบวงจร เพื่อตอบสนองความต้องการของท่าน
                มีการให้คำปรึกษาในด้านการสร้างโดยวิศวกร และสถาปนิกที่เชี่ยวชาญ
                ตามมาตรฐานความแข็งแรงและความมั่นคงของสิ่งก่อสร้าง
                จนกว่าจะได้แบบที่ท่านพึงพอใจ
              </p>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          style={{
            padding: "0",
            marginTop: "30px",
            backgroundColor: "#003f88",
          }}
        >
          <Row
            style={{
              margin: "0",
              color: "#fff",
              fontFamily: "'Thasadith', sans-serif",
            }}
          >
            <Col lg="6" md="12" style={{ padding: "0" }}>
              <img src={require("assets/img/about1.jpg").default} alt="" />
            </Col>
            <Col
              lg="6"
              md="12"
              style={{ padding: "2rem 2rem 0 3rem", fontSize: "1.1rem" }}
            >
              <h2
                style={{
                  fontWeight: "bold",
                  margin: "1rem 0 30px 0",
                }}
              >
                มีประสบการทำงานตั้งแต่ ปี 2549
              </h2>
              <p>
                ห้างหุ้นส่วนจำกัด แสนธรณี ตั้งอยู่ที่ 14/3 หมู่ที่ 7
                ซอยบางกรวย-ไทรน้อย 15/2 ถนนบางกรวย-ไทรน้อย ตำบลบางกรวย
                อำเภอบางกรวย จังหวัดนนทบุรี 11130 <br />{" "}
                ประสบการทำงานที่มีมากกว่า 15 ปี ห้างหุ้นส่วนจำกัดแสนธรณี
                เริ่มทำงานก่อสร้างครั้งแรกตั้งแต่ปี 2549
                โดยเริ่มต้นจากการเป็นช่างรับเหมาจากงานเล็ก ๆ ต่อมาภายในปี 2561
                จึงได้ก่อตั้งห้างหุ้นส่วนจำกัดแสนธรณี ขึ้นมา
                เพื่อต้องการรองรับงานที่มีมูลค่ามากขึ้น
                และสร้างความเชื่อมั่นให้กับท่าน
              </p>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          style={{
            backgroundImage:
              "url(" + require("assets/img/about2.jpg").default + ")",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            paddingBottom: "20px",
          }}
        >
          <Row
            style={{
              margin: "0",
              fontFamily: "'Thasadith', sans-serif",
            }}
          >
            <Col style={{ padding: "2rem 2rem 0 3rem", fontSize: "1.1rem" }}>
              <h2
                style={{
                  fontWeight: "bold",
                  margin: "1rem 0 30px 0",
                  color: "#003f88",
                }}
              >
                บริการของเรา
              </h2>
              <p>
                โดย ห้างหุ้นส่วนจำกัด แสนธรณี ให้บริการรับเหมาก่อสร้าง ต่อเติม
                ซ่อมแซม รีโนเวท บ้าน อาคาร สำนักงาน คอนโดฯ และโรงงาน
              </p>
              <CheckedContent Text="งานซ่อม สร้าง หลังคา งานซ่อมหลังคารั่ว" />
              <CheckedContent Text="งานกันซึมดาดฟ้า ผนังอาคาร" />
              <CheckedContent Text="งานกันรั่วซึมหลังคาโรงงาน หลังคาบ้าน อาคารสำนักงาน" />
              <CheckedContent Text="งานทาสี" />
              <CheckedContent Text="งานซ่อมประตู รั้ว ทั้งเหล็ก ปูน ไม้" />
              <CheckedContent Text="ซ่อม ปู พื้นคอนกรีต งานฝ้า กั้นห้อง กั้นผนังซ่อม สร้างห้องน้ำ" />
              <CheckedContent Text="ระบบ ประปา ไฟฟ้า ฯลฯ" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContentAbout;
