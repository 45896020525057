import React from "react";

import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

const items = [
  {
    src: require("assets/img/slide/85_2.jpg").default,
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/slide/2_2.jpg").default,
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/slide/21_2.jpg").default,
    altText: "",
    caption: "",
  },
];

function AboutUs() {
  const [hover, setHover] = React.useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div
        className="section section-aboutus"
        style={{
          backgroundImage:
            "url(" + require("assets/img/section-bg1.png").default + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col style={{textAlign:"center", marginBottom:"30px"}}>
              {/* <h2 style={{ fontWeight: "bold", fontSize: "2rem" }}>
                เราคือ :{" "}
                <span style={{ color: "red" }}>ผู้ให้บริการรับสร้างบ้าน</span>
              </h2>
              <h2 style={{ fontWeight: "bold", fontSize: "2rem" }}>
                เพื่อการ :{" "}
                <span style={{ color: "red" }}>
                  สร้างบ้านเสร็จสวยครบ จบตามแบบ
                </span>
              </h2>
              <h2
                style={{ fontWeight: "bold", fontSize: "2rem", color: "red" }}
              >
                ด้วยความชำนาญกว่า 15 ปี
              </h2> */}
              <img
              alt="..."
              src={require("assets/img/comp.png").default}
            />
            </Col>
          </Row>

          <Row className="justify-content-center" style={{ direction: "rtl" }}>
            <Col className="ml-auto mr-auto" md="10" lg="6">
              <h3 style={{ marginBottom: "0px" }}>ขอต้อนรับสู่</h3>
              <h2 style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
                ห้างหุ้นส่วนจำกัด แสนธรณี
              </h2>
              <p style={{ fontSize: "1.1rem" }}>
                บริการรับเหมาก่อสร้างครบวงจร ออกแบบ รีโนเวท ต่อเติม
                ครอบคลุมงานก่อสร้างทุกชนิด ไม่ว่าจะเป็น บ้าน อาคาร สำนักงาน
                คอนโดฯ และโรงงานของท่าน เราสามารถรังสรรค์ตามที่ท่านต้องการ
                ด้วยทีมงานคุณภาพ จากประสบการณ์การทำงานที่มีมากกว่า 15 ปี
                พร้อมทั้งมีวิศวกร
                และสถาปนิกที่เชี่ยวชาญคอยควบคุมงานอย่างเคร่งครัด
              </p>
              <p style={{ textAlign: "center" }}>
                <Button
                  outline
                  color="info"
                  href="/about-us"
                  style={{
                    backgroundColor: hover === true ? "#003f88" : "",
                    color: hover === true ? "#fff" : "",
                    transition: ".3s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
                    fontSize: "1.5rem",
                    //marginBottom: "20px",
                    textAlign: "center",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  เกี่ยวกับเรา
                </Button>
              </p>
            </Col>
            <Col
              className="ml-auto mr-auto"
              md="10"
              lg="6"
              style={{ maxHeight: "360px" }}
            >
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                className="carousel-aboutus"
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <div className="image-in-carousel">
                        <img src={item.src} alt={item.altText} />
                      </div>
                      <div className="carousel-caption d-none d-md-block">
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
