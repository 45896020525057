import React from "react";

import { Container, Row, Col } from "reactstrap";

function HeadProfile() {
  return (
    <>
      <div
        className="section section-about"
        style={{
          backgroundImage:
            "url(" + require("assets/img/head-bg2.jpg").default + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="header-all-page-tranparent"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="head-content-all image">
              <img src={require("assets/img/logoAsset-1.png").default} alt="" />
            </Col>
            <Col lg="12" className="head-content-all text-head">
              <h2>ห้างหุ้นส่วนจำกัด แสนธรณี</h2>
            </Col>
          </Row>
          {/* <a
            href="https://www.freepik.com/photos/technology"
            target="_blank"
            className="credit-picture"
          >
            Technology photo created by rawpixel.com - www.freepik.com
          </a> */}
        </Container>
      </div>
    </>
  );
}

export default HeadProfile;
