import React from "react";

import { Container, Row, Col } from "reactstrap";

function HeadProject() {
  return (
    <>
      <div
        className="section section-portfolio"
        style={{
          backgroundImage:
            "url(" + require("assets/img/head-bg2.jpg").default + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="header-all-page-tranparent"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="head-content-all image">
              <img src={require("assets/img/logoAsset-1.png").default} alt="" />
            </Col>
            <Col lg="12" className="head-content-all text-head">
              <h2>ผลงานของเรา</h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HeadProject;
