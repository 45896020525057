export const source = [
  {
    TitleText: 'โครงการปรับปรุงบ้านพักอาศัย 2 ชั้น (หมู่บ้านเสนานิเวศน์ 1 กรุงเทพฯ)',
    ImgCard: require('assets/img/cover/โครงการปรับปรุงบ้านพักอาศัย 2 ชั้น (หมู่บ้านเสนานิเวศน์ 1 กรุงเทพฯ).jpg').default,
    FileName:
      require('assets/pdf/ltp/โครงการปรับปรุงบ้านพักอาศัย 2 ชั้น (หมู่บ้านเสนานิเวศน์ 1 กรุงเทพฯ).pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'โครงการบ้านพักอาศัย 2 ชั้น ซอย นวมินทร์ 111',
    ImgCard: require('assets/img/cover/โครงการบ้านพักอาศัย 2 ชั้น ซอย นวมินทร์ 111.jpg').default,
    FileName:
      require('assets/pdf/ltp/โครงการบ้านพักอาศัย 2 ชั้น ซอย นวมินทร์ 111.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'โครงการปรับปรุงบ้านพักอาศัย 2 ชั้น (รามอินทรา 40 กรุงเทพฯ)',
    ImgCard: require('assets/img/cover/โครงการปรับปรุงบ้านพักอาศัย 2 ชั้น (รามอินทรา 40 กรุงเทพฯ).jpg').default,
    FileName:
      require('assets/pdf/ltp/โครงการปรับปรุงบ้านพักอาศัย 2 ชั้น (รามอินทรา 40 กรุงเทพฯ).pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'โครงการ สร้างบ้านพักอาศัย 2 ชั้น พุทธมณฑลสาย 1',
    ImgCard: require('assets/img/cover/สาย 1.jpg').default,
    FileName:
      require('assets/pdf/wip/5 สาย 1 ดำเนินการ.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'งานปรับปรุงและต่อเติมบ้าน\nซอยรามคำแหง 80',
    ImgCard: require('assets/img/cover/รามคำแหง80 3.jpg').default,
    FileName:
      require('assets/pdf/ltp/7 รีโนเวทบ้านรามคำแหง 80 โดย หจก แสนธรณี.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText:
      'งานก่อสร้างบ้านพักอาศัย 2 ชั้น 1 หลัง บ้าน\nท่านพลอากาศโท แมนศีตะจิตต์',
    ImgCard: require('assets/img/cover/รามอินทรา 21 2.jpg').default,
    FileName:
      require('assets/pdf/ltp/1 โครงการก่อสร้าง บ้านพักอาศัย 2 ชั้น 1 หลัง บ้านท่าน พลอากาศโท แมนศีตะจิตต์.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'งานปรับปรุงและต่อเติมคลีนิกกายภาพบำบัด\nนวมินทร์ 85',
    ImgCard: require('assets/img/cover/นวมินทร์ 85 2.jpg').default,
    FileName: require('assets/pdf/ltp/2 นวมินทร์ 85.pdf').default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'งานปรับปรุงและต่อเติมบ้าน\nซอยจรัญ 18',
    ImgCard: require('assets/img/cover/จรัญ 18 1.jpg').default,
    FileName:
      require('assets/pdf/ltp/3 โครงการปรับปรุงและต่อเติมบ้าน ซอยจรัญ18.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'งานทาสีอาคาร บริษัท แอดวานซ์ อเบรซีฟ',
    ImgCard: require('assets/img/cover/สะแกงาม 1.jpg').default,
    FileName:
      require('assets/pdf/ltp/4 งานทาสีอาคาร บริษัท แอดวานซ์ อเบรซีฟ ซอยสะแกงาม35.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'งานปรับปรุงและต่อเติมบ้าน\nพุทธมณฑลสาย 2',
    ImgCard: require('assets/img/cover/บ้านสาย 2 3.jpeg').default,
    FileName:
      require('assets/pdf/ltp/5 โครงการปรับปรุงและต่อเติมบ้าน พุทธมณฑลสาย 2.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
  {
    TitleText: 'งานปรับปรุงและต่อเติมสำนักงาน รัตนาธิเบศน์',
    ImgCard: require('assets/img/cover/รัตนาธิเบศน์3.jpg').default,
    FileName:
      require('assets/pdf/ltp/6 โครงการปรับปรุงสำนักงาน รัตนาธิเบศน์.pdf')
        .default,
    Blanks: false,
    FetchCard: false,
  },
];