import React from "react";

import { Container, Row, Col } from "reactstrap";

function ChooseChoice(props) {
  const { HeadText, BodyText, IconClass } = props;
  return (
    <>
      <div style={{ margin: "0 0 70px 60px" }}>
        <div style={{ display: "inline-block", position: "absolute" }}>
          <div
            style={{
              width: "80px",
              height: "80px",
              backgroundColor: "#3366cc",
              borderRadius: "40px",
              fontSize: "40px",
              lineHeight: "80px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <i className={IconClass}></i>
          </div>
        </div>
        <div style={{ margin: "0 10px 0 100px", fontSize: "1.1rem" }}>
          <h3 style={{ margin: "0px 0px 7px 0px" }}>{HeadText}</h3>
          <div>{BodyText}</div>
        </div>
      </div>
    </>
  );
}

function WhyChoose() {
  return (
    <>
      <div
        className="section section-whyus"
        style={{
          backgroundColor: "#fafafa",
          padding: "0",
        }}
      >
        <Row style={{ backgroundColor: "#003f88", margin: "0" }}>
          <Col
            lg="6"
            md="12"
            className="d-md-none d-lg-block"
            style={{
              backgroundImage:
                "url(" + require("assets/img/whychoose.jpg").default + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              padding: "0",
            }}
          ></Col>
          <Col lg="6" md="12" style={{ padding: "2rem 0 0 0", color: "#fff" }}>
            <Container style={{ padding: "0" }}>
              <h6 style={{ fontWeight: "500", textAlign: "center" }}>
                WHY TO CHOOSE US
              </h6>
              <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                ทำไมต้องเลือกเรา
              </h2>
              <div
                style={{
                  position: "absolute",
                  borderLeft: "1px dashed #fff",
                  height: "60%",
                  marginLeft: "100px",
                  marginTop: "10px",
                }}
              />
              <ChooseChoice
                IconClass="fas fa-bolt"
                HeadText="ทำงานตรงเวลา ราคายุติธรรม"
                BodyText="ทำงานเสร็จภายในกรอบระยะเวลาที่กำหนด คำนึงถึงงบประมาณของลูกค้าเป็นหลัก"
              />
              <ChooseChoice
                IconClass="fas fa-tools"
                HeadText="ทีมช่างผู้ชำนาญการโดยเฉพาะ"
                BodyText="เรามีทีมงานผู้เชี่ยวชาญในงานก่อสร้างประสบการณ์สูง มาตรฐานตามหลักวิศวกรรม"
              />
              <div style={{ margin: "0 0 70px 60px" }}>
                <div style={{ display: "inline-block", position: "absolute" }}>
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      backgroundColor: "#3366cc",
                      borderRadius: "40px",
                      fontSize: "20px",
                      lineHeight: "80px",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    <span class="fa-stack">
                      <i class="fas fa-certificate fa-stack-2x"></i>
                      <i
                        class="fas fa-check fa-stack-1x"
                        style={{ color: "#3366cc" }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div style={{ margin: "0 10px 0 100px", fontSize: "1.1rem" }}>
                  <h3 style={{ margin: "0px 0px 7px 0px" }}>
                    รับประกันคุณภาพของงาน
                  </h3>
                  <div>
                    การันตีด้วยผลงาน การรับเหมาก่อสร้างครบวงจร
                    ครอบคลุมงานก่อสร้าง อาคารทุกประเภท
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default WhyChoose;
