import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import About from "views/About-us";
import Services from "views/Servieces";
import Project from "views/Project";
import Contact from "views/Contact-us";
import Profile from "views/Profile";

import ImageWall from "views/Image-wall";

// import ImageWall from "views/Image-wall";
// import NucleoIcons from "views/NucleoIcons.js";
// import LoginPage from "views/examples/LoginPage.js";
// import LandingPage from "views/examples/LandingPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route path="/about-us" render={(props) => <About {...props} />} />
        <Route path="/services" render={(props) => <Services {...props} />} />
        <Route path="/portfolio" render={(props) => <Project {...props} />} exact />
        <Route path="/portfolio/:id" render={(props) => <ImageWall {...props} isWIP={false} />} />
        <Route path="/wip/:id" render={(props) => <ImageWall {...props} isWIP />} />
        <Route path="/contact-us" render={(props) => <Contact {...props} />} />
        <Route path="/profile" render={(props) => <Profile {...props} />} />

        {/* <Route path="/img-test" render={(props) => <ImageWall {...props} />} /> */}
        {/*<Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        /> */}
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
