import React from 'react';

import { Container, Row, Col } from 'reactstrap';

function HeadImageWall(props) {
  const { HeadName, bgHeadImage } = props;
  return (
    <>
      <div
        className="section section-about"
        style={{
          backgroundImage: `url(${bgHeadImage})` ,
          // "url(" + require("assets/img/head-bg.jpg").default + ")",
          // "url(" + require("assets/img/cover/นวมินทร์ 85 2.jpg").default+")",
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="header-all-page-tranparent"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="head-content-all image">
              <img src={require('assets/img/logoAsset-1.png').default} alt="" />
            </Col>
            <Col lg="12" className="head-content-all text-head">
              <h2>{HeadName}</h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HeadImageWall;
