import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
import { source } from './projectFixed/source';
import BarLoader from 'react-spinners/BarLoader';

function ProjectManages(Page, Count, Source, HeightMax) {
  const Limits = Page * Count;
  const SourceCount = Source.length;
  const PackProject = [];
  if (Limits > SourceCount) {
    for (let i = 0; i < Page; i++) {
      let k = i * Count;
      let temp = [];
      for (let j = 0; j < Count; j++) {
        if (SourceCount > k + j) {
          temp.push(ProjectRows(Source[k + j], HeightMax));
        } else {
          temp.push(SourceBlank(HeightMax));
        }
      }
      PackProject.push({
        src: ProjectSrc(temp),
      });
    }
  } else {
    for (let i = 0; i < Page; i++) {
      let k = i * Count;
      let temp = [];
      for (let j = 0; j < Count; j++) {
        temp.push(ProjectRows(Source[k + j], HeightMax));
      }
      PackProject.push({
        src: ProjectSrc(temp),
      });
    }
  }
  return PackProject;
}

function ProjectSrc(srcRow) {
  return (
    <Row className="project-layout">
      {srcRow.map((item) => {
        return item;
      })}
    </Row>
  );
}

function ProjectRows(Source, HeightMax) {
  return (
    <ProjectCard
      TitleText={Source.TitleText}
      ImgCard={Source.ImgCard}
      FileName={Source.FileName}
      setMaxHeight={HeightMax}
      Blanks={Source.Blanks}
      FetchCard={Source.FetchCard}
    />
  );
}
function SourceBlank(HeightMax) {
  return (
    <ProjectCard
      TitleText={''}
      ImgCard={require('assets/img/cover/blanks.jpg').default}
      //FileName={require("").default}
      setMaxHeight={HeightMax}
      Blanks={true}
    />
  );
}

function ProjectCard(props) {
  // eslint-disable-next-line
  const {
    TitleText,
    ImgCard,
    FileName,
    sendID,
    setMaxHeight,
    Blanks,
    FetchCard,
  } = props;
  return (
    <>
      <a
        className={
          Blanks
            ? 'project-card-showcase content-hidden'
            : 'project-card-showcase'
        }
        href={FileName}
        target={FetchCard ? '_self' : '_blank'}
        rel="noreferrer"
      >
        <div className="project-card-div" id={sendID}>
          <img
            src={ImgCard}
            alt=""
            // style={{ height: setMaxHeight, width: "100%" }}
          />
          <div className="project-card-text">
            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {TitleText}
            </span>
            <span style={{ fontSize: '0.8rem' }}>View Project</span>
          </div>
        </div>
      </a>
    </>
  );
}

function LastProject() {
  const [post, setPost] = useState(null);
  const [topic, setTopic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      // console.log('try to get');
      const res = await axios.get('/api/posts/');
      setPost(res.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // setHeight(document.getElementById('mainHeightProject').clientHeight);
    // console.log('started effect');
    getData();
  }, []);

  useEffect(() => {
    if (post) {
      // setTopic(post);
      let temp = [];
      for (let i = 0; i < post.length; i++) {
        temp.push({
          TitleText: post[i].title,
          ImgCard: post[i].img,
          FileName: `/portfolio/${post[i].post}`,
          Blanks: false,
          FetchCard: true,
        });
      }
      setTopic([...temp, ...source]);
    }
  }, [post]);

  // useEffect(() => {
  //   console.log('post');
  //   console.log(post);
  //   console.log('topic');
  //   console.log(topic);
  // }, [topic]);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const [activeIndex2, setActiveIndex2] = React.useState(0);
  const [animating2, setAnimating2] = React.useState(false);
  const onExiting2 = () => {
    setAnimating2(true);
  };
  const onExited2 = () => {
    setAnimating2(false);
  };
  const next2 = () => {
    if (animating2) return;
    const nextIndex2 =
      activeIndex2 === itemsFull.length - 1 ? 0 : activeIndex2 + 1;
    setActiveIndex2(nextIndex2);
  };
  const previous2 = () => {
    if (animating) return;
    const nextIndex2 =
      activeIndex2 === 0 ? itemsFull.length - 1 : activeIndex2 - 1;
    setActiveIndex2(nextIndex2);
  };
  const goToIndex2 = (newIndex2) => {
    if (animating2) return;
    setActiveIndex2(newIndex2);
  };

  const [heightMax, setHeight] = useState(0);
  // useEffect(() => {
  //   setHeight(document.getElementById('mainHeightProject').clientHeight);
  // }, []);

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         width: '100vw',
  //         height: '100vh',
  //         zIndex: '9999',
  //         backgroundColor: '#fff',
  //         position: 'fixed',
  //         top: '0',
  //         left: '0',
  //         flexDirection: 'column',
  //         fontSize: '50px',
  //         fontWeight: 'bold',
  //         color: '#003f88',
  //       }}
  //     >
  //       <div>Loading...</div>
  //       {/* <img src={require('assets/img/logoAsset-1.png').default} alt="" /> */}
  //       <BarLoader color="#003f88" height={20} width={300} />
  //     </div>
  //   );
  // }

  // if (error) {
  //   return <div>error</div>;
  // }
  //Page, Count, Source, HeightMax
  const items = ProjectManages(3, 3, topic, heightMax);
  const itemsFull = ProjectManages(2, 4, topic, heightMax);
  return (
    <>
      <div
        className="section section-portfolio"
        style={{
          backgroundColor: '#fafafa',
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              lg="12"
              style={{
                textAlign: 'center',
                fontSize: '1.1rem',
              }}
            >
              <h6 style={{ fontWeight: '500' }}>LASTEST PROJECTS</h6>
              <h2 style={{ fontWeight: 'bold' }}>ผลงานของเรา</h2>
              <p>
                ด้วยทีมงานช่างผู้เชี่ยวชาญของเรา
                เราจึงกล้ารับประกันคุณภาพงานทุกงานที่ทำ ให้กับลูกค้า ตั้งแต่ 6
                เดือน จนถึง 5 ปี
              </p>
            </Col>
          </Row>
        </Container>
        <Row className="d-block d-md-none d-lg-none d-xl-none">
          <Container fluid={true}>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className="carousel-lastproject"
            >
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item, index) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={index}
                  >
                    <div className="project-in-carousel">{item.src}</div>
                  </CarouselItem>
                );
              })}
              <a
                className="carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-left"></i>
              </a>
              <a
                className="carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-right"></i>
              </a>
            </Carousel>
          </Container>
        </Row>
        <div className="d-none d-md-block d-lg-block d-xl-block">
          <Container fluid={true}>
            <Carousel
              activeIndex={activeIndex2}
              next={next2}
              previous={previous2}
              className="carousel-lastproject"
            >
              <CarouselIndicators
                items={itemsFull}
                activeIndex={activeIndex2}
                onClickHandler={goToIndex2}
              />
              {itemsFull.map((item, index) => {
                return (
                  <CarouselItem
                    onExiting={onExiting2}
                    onExited={onExited2}
                    key={index}
                  >
                    <div className="project-in-carousel">{item.src}</div>
                  </CarouselItem>
                );
              })}
              <a
                className="carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous2();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-left"></i>
              </a>
              <a
                className="carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next2();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-right"></i>
              </a>
            </Carousel>
          </Container>
        </div>
        <Row className="justify-content-center">
          <p style={{ marginTop: '2rem' }}>
            <Button
              color="info"
              style={{
                margin: '10px',
                borderRadius: '10px',
                width: '271px',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                textAlign: 'center',
              }}
              href="/portfolio"
            >
              ผลงานของเราทั้งหมด
            </Button>
          </p>
        </Row>
      </div>
    </>
  );
}

export default LastProject;
