import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import emailjs from "emailjs-com";

function ContactBlock(props) {
  const { Text1, Text2, Link, Icon, Color } = props;
  return (
    <a
      className={"btn contact-block " + Color}
      href={Link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="contact-block-icon">
        <i className={Icon}></i>
      </div>
      <div className="contact-block-text">
        <p>{Text1}</p>
        <p>{Text2}</p>
      </div>
    </a>
  );
}

function ContentContact() {
  const [Fullname, setFullname] = useState("");
  const [Tel, setTel] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");

  const handleChange = (event) => {
    event.target.name === "saen_fullname"
      ? setFullname(event.target.value)
      : event.target.name === "saen_telephone"
      ? setTel(event.target.value)
      : event.target.name === "saen_email"
      ? setEmail(event.target.value)
      : event.target.name === "saen_subject"
      ? setSubject(event.target.value)
      : event.target.name === "saen_message"
      ? setMessage(event.target.value)
      : console.log("error");
  };

  function sendEmail(e) {
    e.preventDefault();
    //sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "service_474drvg",
        "template_ddfr9kr",
        e.target,
        "user_GDGXpjd4bBUg4AETk30Cj"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFullname("");
          setTel("");
          setEmail("");
          setSubject("");
          setMessage("");
        },
        (error) => {
          console.log(error.text);
          setFullname("");
        }
      );
  }
  return (
    <>
      <div
        className="section section-content"
        style={{
          backgroundColor: "#fefefe",
          paddingBottom: "0",
        }}
      >
        <Container
          style={{
            marginTop: "30px",
            paddingBottom: "70px",
          }}
        >
          <Row
            style={{
              fontFamily: "'Thasadith', sans-serif",
              fontSize: "1.1rem",
            }}
          >
            <Col lg="6" md="12">
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#003f88",
                  margin: "1rem 0 30px 0",
                }}
              >
                ห้างหุ้นส่วนจำกัด แสนธรณี
              </h2>
              <p>
                ตั้งอยู่ที่ 14/3 หมู่ที่ 7 ซอยบางกรวย-ไทรน้อย 15/2
                ถนนบางกรวย-ไทรน้อย ตำบลบางกรวย อำเภอบางกรวย จังหวัดนนทบุรี 11130
              </p>
              <div className="contact-block-wrap">
                <ContactBlock
                  Text1="โทร : คุณอัมรา"
                  Text2="063-354-5397"
                  Link="tel:+6663-354-5397"
                  Icon="fas fa-phone-alt"
                  Color="default"
                />
                <ContactBlock
                  Text1="โทร : ช่างเอ"
                  Text2="087-499-6359"
                  Link="tel:+6687-499-6359"
                  Icon="fas fa-phone-alt"
                  Color="default"
                />
              </div>
              <div className="contact-block-wrap">
                <ContactBlock
                  Text1="โทร : คุณกฤษฎา"
                  Text2="080-965-3550"
                  Link="tel:+6680-965-3550"
                  Icon="fas fa-phone-alt"
                  Color="default"
                />
                <ContactBlock
                  Text1="ติดต่อทางอีเมล์"
                  Link="mailto:saenthoraneegw@gmail.com"
                  Icon="fas fa-envelope"
                  Color="default"
                />
              </div>
              <div className="contact-block-wrap">
                <ContactBlock
                  Text1="ติดต่อทางเฟซบุ๊ก"
                  Link="https://fb.me/Saenthorranee"
                  Icon="fab fa-facebook"
                  Color="facebook"
                />
                <ContactBlock
                  Text1="ติดต่อทางไลน์"
                  Link="https://line.me/R/ti/p/@383hkjwu"
                  Icon="fab fa-line"
                  Color="line"
                />
              </div>
            </Col>
            <Col lg="6" md="12">
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#003f88",
                  margin: "1rem 0 30px 0",
                }}
              >
                แบบฟอร์มขอใบเสนอราคา
              </h2>
              <Form onSubmit={sendEmail}>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        name="saen_fullname"
                        defaultValue=""
                        placeholder="ชื่อ-สกุล"
                        type="text"
                        value={Fullname}
                        onChange={(e) => handleChange(e)}
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Input
                        name="saen_telephone"
                        defaultValue=""
                        placeholder="โทรศัพท์"
                        type="text"
                        value={Tel}
                        onChange={(e) => handleChange(e)}
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Input
                        name="saen_email"
                        defaultValue=""
                        placeholder="อีเมล์"
                        type="text"
                        value={Email}
                        onChange={(e) => handleChange(e)}
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        name="saen_subject"
                        defaultValue=""
                        placeholder="หัวข้อ"
                        type="text"
                        value={Subject}
                        onChange={(e) => handleChange(e)}
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        name="saen_message"
                        defaultValue=""
                        placeholder="ข้อความ"
                        type="textarea"
                        value={Message}
                        onChange={(e) => handleChange(e)}
                        rows="4"
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Button
                    color="info"
                    type="submit"
                    style={{
                      margin: "10px",
                      borderRadius: "10px",
                      width: "150px",
                    }}
                  >
                    ส่ง
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="justify-content-center">
            <Col
              lg="12"
              style={{
                textAlign: "center",
                fontSize: "1.1rem",
                padding: "0",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d968.6398488888547!2d100.48974100000001!3d13.805414!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x40f7d335af341dd8!2z4Lir4LiI4LiBLuC5geC4quC4meC4mOC4o-C4k-C4tQ!5e0!3m2!1sth!2sth!4v1672911228937!5m2!1sth!2sth"
                width="100%"
                height="450"
                style={{ border: 0, minHeight: "100%" }}
                allowfullscreen=""
                loading="lazy"
                title="สถานที่ตั้ง"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContentContact;
